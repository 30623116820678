import styled, { DefaultTheme, css } from 'styled-components';
import { RenderProp } from '@utils/react';

interface SectionHeadingProps {
  children?: RenderProp<never>;
  variant: keyof DefaultTheme['section'];
}

export const SectionSubtitle = styled.p<{ variant: SectionHeadingProps['variant'] }>`
  font-size: 0.5em;
  color: ${(props) => props.theme.section[props.variant].subtitle};
  font-weight: ${(props) => props.theme.fontWeights.body};
  margin-top: 0;
  margin-bottom: 0;
`;

export const SectionHeader = styled.header<SectionHeadingProps>`
  ${(props) => {
    const { variant = 'secondary' } = props;
    return css`
      color: ${props.theme.section[variant].heading};
      font-size: ${props.theme.fontSizes.h2};
      margin: 0 0 29px 0;

      h2,
      h3 {
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1em;

        &::before {
          content: '»';
          display: inline-block;
          position: absolute;
          left: -0.7em;
          font-size: 1.2em;
          top: 50%;
          transform: translateY(-50%);
          line-height: 1;
          font-weight: ${props.theme.fontWeights.body};
        }
      }
    `;
  }};
`;

SectionHeader.defaultProps = {
  children: null,
  variant: 'secondary',
};
