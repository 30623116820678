import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.h1};
  line-height: ${(props) => props.theme.lineHeights.heading};
`;

export const H2 = styled.h2`
  font-size: ${(props) => props.theme.fontSizes.h2};
  line-height: ${(props) => props.theme.lineHeights.heading};
`;

export const H3 = styled.h2`
  font-size: ${(props) => props.theme.fontSizes.h3};
  line-height: ${(props) => props.theme.lineHeights.heading};
`;
