import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@components/layout/primary';
import SEO from '@components/SEO';

import { SDAditiMohanty, SDThisSite } from '@data/structured/homepage';
import { createGlobalStyle } from 'styled-components';
import { Projects } from '@views/home/projects';
import { HeroSection } from '@views/home/Hero';

const HomePageGlobals = createGlobalStyle`
  ${(props) => props.theme.mediaQueries.screenMedium} {
    .hero-container {
      min-height: 500px;

      @media screen and (min-height: 500px) {
        min-height: 90vh;
        min-height: calc((var(--vh, 1vh) * 100) - 60px);
      }
    }
  }
`;

export const HomePage: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO title='Home' pathname={location.pathname}>
        <SDAditiMohanty />
        <SDThisSite />
      </SEO>
      <HomePageGlobals />
      <HeroSection />
      <Projects />
    </Layout>
  );
};
