import styled from 'styled-components';
import { MaxWidthContainer } from '@components/container';
import { SectionHeader } from '@components/heading';

export const ProjectsContainer = styled(MaxWidthContainer)`
  padding-top: 60px;
  padding-bottom: 60px;

  /* ${SectionHeader} {
    margin-bottom: 70px;
  } */
`;

export const ProjectList = styled.ul`
  margin: 0 0 2rem 0;
  padding: 0;
  list-style: none;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 50px 50px;

  @media screen and (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media all and (-ms-high-contrast: none) {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: repeat(4, 1fr);
  }

  @media all and (min-width: 800px) and (-ms-high-contrast: none) {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: repeat(4, 1fr);
  }
`;
