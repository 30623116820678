import React from 'react';
import { Link } from 'gatsby';

// components
import { Flex, Box, Hide } from '@components/Reflexbox';
import { Highlight } from '@components/util';
import { MaxWidthContainer } from '@components/container';
import * as Hero from '@components/HeroTitle';
import SocialLinks from '@components/SocialLink';

// images
import OpenPeep from '@images/openpeepme.inline.svg';
import OpenPeepSmall from '@images/openpeep.small.png';

// utils
import { onClickExternalLink, trackEvent } from '@utils/analytics';

export const HeroSection: React.FC = () => {
  return (
    <MaxWidthContainer>
      <Flex
        maxHeight='1000px'
        justifyContent='center'
        alignItems='center'
        paddingTop={['30px', 0, 0]}
        className='hero-container'
      >
        <Box width={1} maxWidth='1280px'>
          <Flex justifyContent={['space-around', 'space-between', 'space-between']} alignItems='center'>
            <Box width={[1, 9 / 12, 5 / 12]}>
              <Flex flexDirection='column'>
                <Box order={[1, 1, 1]}>
                  <Hero.Title mb={['0.5em', '1em', '1em']} mt={0}>
                    <Hero.Subtitle>
                      <span role='img' aria-label='wave'>
                        👋
                      </span>
                      Hello! My name is
                    </Hero.Subtitle>
                    {`Aditi Mohanty`}
                    <Highlight>.</Highlight>
                  </Hero.Title>
                </Box>
                <Box order={[3, 3, 3]} hide='screenMedium'>
                  <img src={OpenPeepSmall} alt='me' style={{ width: '100%' }} className='peep-me desktop' />
                </Box>
                <Box order={[4, 4, 4]} className='hero-explainer'>
                  <p>
                    {`I'm a `}
                    <span>human</span>
                    {` from `}
                    <a
                      href='https://goo.gl/maps/hLujGZuwhY2T4nRN6'
                      target='_blank'
                      rel='noopener noreferrer nofollow'
                      onClick={onClickExternalLink}
                    >
                      Bangalore, India
                    </a>{' '}
                    who ❤s Javascript,
                    <Hide hide='screenSmallOnly'>
                      <br />
                    </Hide>{' '}
                    video-games &amp; music.
                  </p>

                  <p>
                    {`I'm currently building front-end stuff at `}
                    <a href='https://www.atlassian.com/' onClick={onClickExternalLink}>
                      Atlassian
                    </a>
                    {'. You can find my '}
                    <Link
                      to='/resume'
                      onClick={() => {
                        trackEvent(
                          { action: 'click', label: `resume here` },
                          {
                            transport: 'beacon',
                            dimension1: '/resume',
                          }
                        );
                      }}
                    >
                      resume here
                    </Link>
                    .
                  </p>
                  <p>
                    I go by{' '}
                    <a href='https://twitter.com/rheaditi' onClick={onClickExternalLink} rel='nofollow'>
                      @rheaditi
                    </a>{' '}
                    on the interwebs.
                  </p>
                </Box>
                <Box order={[2, 4, 4]}>
                  <SocialLinks />
                </Box>
              </Flex>
            </Box>
            <Box width={[1, 9 / 12, 5 / 12]} hide='screenSmallOnly'>
              <OpenPeep className='peep-me' style={{ width: '100%' }} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </MaxWidthContainer>
  );
};
