import { SoftwareSourceCode } from 'schema-dts';
import CasualDBBackground from '@assets/img/projects/casualdb-bg.svg';

import BoxShadowsBackground from '@assets/img/projects/boxshadows-bg.svg';
import DittoBackground from '@assets/img/projects/ditto-bg.svg';

export interface Project {
  description: string;
  name: string;
  source: string;
  tags: string[];
  website: string;
  structuredData: SoftwareSourceCode;
  backgroundLogo: string;
}

export const projects: Project[] = [
  {
    name: 'ditto',
    description: 'A CLI tool to convert your Visual Studio Code theme to an iTerm2 theme. 🎨',
    source: 'https://github.com/campvanilla/ditto',
    tags: ['cli', 'theme', 'vscode', 'iterm'],
    website: 'https://www.npmjs.com/package/@campvanilla/ditto-cli',
    structuredData: <SoftwareSourceCode>{
      codeRepository: 'https://github.com/campvanilla/ditto',
      codeSampleType: 'module',
      programmingLanguage: 'javascript',
      runtimePlatform: 'shell',
    },
    backgroundLogo: DittoBackground,
  },
  {
    description: 'a simple JSON based “database” for deno with a type-safe API.',
    name: 'casualdb',
    source: 'https://github.com/campvanilla/casualdb',
    tags: ['deno', 'typescript', 'prototyping'],
    website: 'https://deno.land/x/casualdb',
    structuredData: <SoftwareSourceCode>{
      codeRepository: 'https://github.com/campvanilla/casualdb',
      codeSampleType: 'module',
      programmingLanguage: 'typescript',
      runtimePlatform: 'deno',
    },
    backgroundLogo: CasualDBBackground,
  },
  {
    description: 'a curated set of beautiful box-shadow presets and an in-browser editor for devs & designers.',
    name: 'boxshadows.com',
    source: 'https://github.com/campvanilla/boxshadows',
    tags: ['typescript', 'now.sh', 'design-tool'],
    website: 'https://boxshadows.com/?utm_source=aditimohanty.com&utm_medium=landing-page&utm_content=projects',
    structuredData: <SoftwareSourceCode>{
      codeRepository: 'https://github.com/campvanilla/boxshadows',
      codeSampleType: 'source-code',
      programmingLanguage: 'typescript',
      isAccessibleForFree: true,
    },
    backgroundLogo: BoxShadowsBackground,
  },
];
