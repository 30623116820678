import React, { useCallback } from 'react';
import styled from 'styled-components';
import { JsonLd } from 'react-schemaorg';
import { SoftwareSourceCode } from 'schema-dts';

import { Project } from '@data/projects';
import { H3 } from '@components/heading';
import { trackEvent } from '@utils/analytics';

// media
import Github from '@assets/img/icons/github.inline.svg';
import ExternalLink from '@assets/img/icons/external-link.inline.svg';
import { lighten } from 'polished';

export interface ProjectCardProps {
  project: Project;
  action: 'website' | 'source';
}

const Description = styled.p`
  padding-right: 40%;
  flex: 1 1 auto;
`;

const Header = styled.header`
  display: flex;

  ${H3} {
    margin-top: 0;
    margin-bottom: 0;
    flex: 1 1 auto;
  }
`;

const Card = styled.article<{ backgroundLogo: string }>`
  background-color: ${(props) => props.theme.card.project.background};
  color: ${(props) => props.theme.card.project.text};
  border-radius: ${(props) => props.theme.card.project.radius};
  box-shadow: ${(props) => props.theme.card.project.shadow};
  cursor: pointer;
  position: relative;
  opacity: ${(props) => (props.theme.flags.dark ? 1 : 0.8)};

  width: 100%;
  height: 100%;
  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition-timing-function: ease-out;
  transition-property: transform;
  transition-duration: 0.15s;

  background-image: url(${(props) => props.backgroundLogo});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;

  :after {
    display: block;
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 6px;
    background: none;
  }

  &:hover {
    transform: translateY(-6px);
    opacity: 1;
  }

  &:hover :after {
    transform: translateY(6px);
  }
`;

const Links = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-template-rows: min-content;
  gap: 20px 20px;

  li {
    line-height: 0;
  }

  a {
    display: inline-flex;
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.card.project.highlight};

    &:hover {
      color: ${(props) => lighten(0.1, props.theme.card.project.highlight)};
    }

    svg {
      vertical-align: middle;
    }
  }
`;

const Tag = styled.code`
  font-size: 0.65em;
  font-family: ${(props) => props.theme.fonts.monospace};
  color: ${(props) => props.theme.card.project.tag};
`;

const Footer = styled.footer`
  display: inline-flex;

  ${Tag} {
    margin-right: 1.5em;
  }
`;

export const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const { project } = props;

  const onActivate = useCallback((event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const tagName = target.tagName.toUpperCase();
    const shouldNavigate = tagName !== 'SVG' && tagName !== 'PATH' && tagName !== 'A';
    const defaultAction = props.project[props.action || 'website'];

    if (shouldNavigate && defaultAction) {
      const newTab = event.metaKey || event.ctrlKey;

      trackEvent(
        {
          action: 'click',
          label: 'project-card',
        },
        {
          dimension1: defaultAction,
          transport: 'beacon',
        }
      );

      if (newTab) {
        window.open(defaultAction, '_blank');
      } else {
        window.location.href = defaultAction;
      }
    }
  }, []);

  return (
    <Card
      onClick={onActivate}
      backgroundLogo={project.backgroundLogo}
      tabIndex={0}
      role='button'
      onKeyPress={onActivate}
    >
      {project.structuredData && (
        <JsonLd<SoftwareSourceCode>
          item={{
            '@context': 'https://schema.org',
            'name': project.name,
            ...project.structuredData,
          }}
        />
      )}
      <Header>
        <H3>{project.name}</H3>
        <Links>
          <li>
            <a
              href={project.source}
              title='View the source code on GitHub'
              data-ga={true}
              data-ga-label={`${project.name}-source`}
            >
              <Github width='20' data-ga={true} data-ga-label={`${project.name}-source`} />
            </a>
          </li>
          <li>
            <a
              href={project.website}
              title='View the source code on GitHub'
              data-ga={true}
              data-ga-label={`${project.name}-website`}
            >
              <ExternalLink width='20' data-ga={true} data-ga-label={`${project.name}-website`} />
            </a>
          </li>
        </Links>
      </Header>
      <Description>{project.description}</Description>
      <Footer>
        {project.tags?.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </Footer>
    </Card>
  );
};

ProjectCard.defaultProps = {
  action: 'website',
};
